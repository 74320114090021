import { EuiMark, EuiMarkdownFormat } from "@elastic/eui";

const Comment = ({ text }) => {
	const youtubeRegex =
		/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(?:embed\/)?(?:v\/)?(?:shorts\/)?(?:\S+)/g;

	const renderComment = () => {
		if (!text) return <div>Pas de commentaire du correcteur</div>;

		const videoUrls = text.match(youtubeRegex) || [];
		const textParts = text.split(youtubeRegex);

		console.log("Video URLs", videoUrls);
		console.log("Text Parts", textParts);

		const parts = textParts.map((part, index) => {
			return (
				<span key={`text-${index}`}>
					<EuiMarkdownFormat>{part}</EuiMarkdownFormat>
				</span>
			);
		});

		videoUrls.forEach((url, index) => {
			const videoId = extractVideoId(url);
			parts.splice(
				index * 2 + 1,
				0,
				<div
					key={`video-${index}`}
					style={{ marginTop: "10px", marginBottom: "10px" }}
				>
					<iframe
						width="100%"
						height="315"
						src={`https://www.youtube.com/embed/${videoId}`}
						frameBorder="0"
						allowFullScreen
						style={{ maxWidth: "700px" }}
					></iframe>
				</div>
			);
		});

		return parts;
	};

	const extractVideoId = (url) => {
		const match = url.match(
			/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(?:embed\/)?(?:v\/)?(?:shorts\/)?(\S+)/
		);
		return match ? match[1] : null;
	};

	return <div>{renderComment()}</div>;
};

export default Comment;
