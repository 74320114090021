import {
	EuiPageTemplate,
	EuiPageHeader,
	EuiButton,
	EuiSpacer,
	EuiFlexGrid,
	EuiFlexItem,
	EuiCard,
	EuiCallOut,
} from "@elastic/eui";
import { Redirect, Router, useMatch } from "@reach/router";
import { useContext, useEffect, useState } from "react";
import { useApi } from "../../effects/useApi";
import LaboratoryFormations from "./Formations/Index";
import { Laboratory, User } from "../../types";
import LaboratoryMembers from "./Members";
import LaboratoryStatusChanges from "./StatusChanges";
import LaboratoryStats from "./Stats";
import Settings from "./Settings";

export default function LaboratoryIndex({
	path,
	laboratory_id,
	navigate,
}: {
	path: string;
	laboratory_id?: string;
	navigate?: (path: string) => void;
}) {
	const [laboratory, setLaboratory] = useState<Laboratory | null>(null);

	const [fetch_laboratory] = useApi(
		"GET",
		`laboratories/${laboratory_id}`,
		(err, laboratory) => {
			if (err) {
				console.error(err);
				return;
			}
			setLaboratory(laboratory);
		}
	);

	const [me, setMe] = useState<User | null>(null);

	const [fetch_me] = useApi("GET", "users/me", (err, user) => {
		if (!err) {
			setMe(user);
		}
	});

	useEffect(() => {
		fetch_laboratory();
		fetch_me();
	}, [laboratory_id]);

	const { action } = useMatch("/laboratories/:laboratory_id/:action/*") || {
		action: "formations",
	};

	if (!laboratory || !me) {
		return (
			<EuiPageTemplate>
				<EuiPageHeader
					// @ts-ignore
					breadcrumb={{ text: "Laboratories", href: "/laboratories" }}
					// @ts-ignore
					rightSideItems={[
						<EuiButton href="/laboratories">Back</EuiButton>,
					]}
				>
					<h1>Loading...</h1>
				</EuiPageHeader>
			</EuiPageTemplate>
		);
	}

	let tabs = [
		{
			label: "Évaluations",
			isSelected: action === "formations",
			onClick: () =>
				navigate(`/laboratories/${laboratory_id}/formations`),
			can_see: ["referent"],
		},
		{
			label: "Membres",
			isSelected: action === "members",
			onClick: () => navigate(`/laboratories/${laboratory_id}/members`),
			can_see: ["referent"],
		},
		// {
		// 	label: 'Auteurs',
		// 	isSelected: action === 'qualiticians',
		// 	onClick: () => navigate(`/laboratories/${laboratory_id}/qualiticians`),
		// 	can_see: ["referent"]
		// },
		{
			label: "Statistiques",
			isSelected: action === "stats",
			onClick: () => navigate(`/laboratories/${laboratory_id}/stats`),
			can_see: ["referent", "corrector", "verificator"],
		},
		{
			label: "Suivi",
			isSelected: action === "status_changes",
			onClick: () => navigate(`/laboratories/${laboratory_id}/status_changes`),
			can_see: ["referent"],
		},
		{
			label: "Réglages",
			isSelected: action === "settings",
			onClick: () => navigate(`/laboratories/${laboratory_id}/settings`),
			can_see: ["referent"],
		},
	];

	// if(me && me.formation_corrector_of.length) {
	// 	tabs.push({
	// 		label: 'Mes corrections',
	// 		isSelected: action === 'corrections',
	// 		onClick: () => navigate(`/laboratories/${laboratory_id}/corrections`),
	// 		can_see: ["corrector", "verificator"]
	// 	})
	// }

	return (
		<EuiPageTemplate>
			<EuiPageTemplate.Header
				pageTitle={laboratory.name}
				tabs={tabs.filter(({ can_see }) => {
					if (
						can_see.includes("referent") &&
						me.referent_of.includes(laboratory._id)
					) {
						return true;
					}

					if (
						can_see.includes("corrector") &&
						me.formation_corrector_of.length
					) {
						return true;
					}

					if (
						can_see.includes("verificator") &&
						me.biologist_verificator_of.length
					) {
						return true;
					}

					return false;
				})}
				rightSideItems={[]}
			>
				{laboratory.active_users >= laboratory.users_quota && (
					<EuiCallOut title="Quota dépassé" color="danger">
						<p>
							Vous avez atteint votre quota d'opérateurs, vous ne
							pouvez plus ajouter d'utilisateurs. Veuillez
							contacter l'équipe de biologie habilitation pour
							augmenter votre quota.
						</p>
						{/* <p>Le quota de {laboratory.users_quota} utilisateurs est dépassé. Vous ne pouvez plus ajouter de nouveaux utilisateurs.</p> */}
					</EuiCallOut>
				)}
			</EuiPageTemplate.Header>

			<EuiPageTemplate.Section>
				<Router primary={false}>
					<LaboratoryFormations
						path="formations/*"
						laboratory_id={laboratory_id}
					/>
					<LaboratoryMembers
						path="members/*"
						laboratory_id={laboratory_id}
					/>
					<LaboratoryStatusChanges
						path="status_changes/*"
						laboratory_id={laboratory_id}
						me={me}
					/>
					{/* <LaboratoryQualiticians path="qualiticians/*" laboratory_id={laboratory_id} /> */}
					<LaboratoryStats
						path="stats/*"
						laboratory_id={laboratory_id}
						me={me}
					/>
					<Settings path="settings/*" laboratory_id={laboratory_id} />
					{/* <FormationAdmin path="/formations/:formation_id/*" /> */}
				</Router>
			</EuiPageTemplate.Section>
		</EuiPageTemplate>
	);
}
